import axios from 'axios'
import {getToken, removeToken} from '@/utils/auth'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// service.responseWithoutMessage = false;

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Authorization'] = 'Bearer ' + getToken()
    return config;
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.headers['content-type'].match(/application\/json/)) {
      const res = response.data;
      if (res.error === 101) {
        router.push({
          name: 'login',
        });
      } else {
        return res
      }
    } else {
      return response
    }
  },
  error => {
    console.error('请求错误', error) // for debug
    return Promise.reject(error)
  }
)

export default service
