<template>
  <div class="wrap">
    <div class="header">
      <div class="avatar">
        <img alt :src="userInfo.avatar" />
      </div>
      <div class="text">
        <div class="line">Hello {{ userInfo.nickname }}</div>
        <div class="line">欢迎使用</div>
      </div>
    </div>
  </div>
</template>

<script>

const refreshUserInfoStorageKey = 'refreshUserInfoStorageKey2';
export default {
  name: 'index',
  components: {

  },
  data() {
    return {

    }
  },
  computed: {
    // 用户信息
    userInfo: function () {
      return this.$store.state.userInfo;
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.wrap{
  padding: .8rem 1.5rem;
  color: #333333;
}
.header{
  display: flex;
  padding: 0 0 1rem 0rem;
  .avatar{
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1.7rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .text{
    text-align: left;
    padding: .4rem 0 0;
    font-size: 1.2rem;
    color: #000000;
    font-weight: bold;
  }
}
</style>
