import request from "@/utils/request";

export function getUserInfo(params) {
  return request({
    url: '/operating/getUserInfo',
    method: 'get',
    params
  })
}

export function orderCountData(params) {
  return request({
    url: '/operating/orderCountData',
    method: 'get',
    params
  })
}

export function orderAmountData(params) {
  return request({
    url: '/operating/orderAmountData',
    method: 'get',
    params
  })
}

export function stickerCountData(params) {
  return request({
    url: '/operating/stickerCountData',
    method: 'get',
    params
  })
}

export function stickerAmountData(params) {
  return request({
    url: '/operating/stickerAmountData',
    method: 'get',
    params
  })
}

export function getRecentlyOrderAmount(params) {
  return request({
    url: '/operating/getRecentlyOrderAmount',
    method: 'get',
    params
  })
}

export function getRecentlyCount(params) {
  return request({
    url: '/operating/getRecentlyCount',
    method: 'get',
    params
  })
}

export function  getRecentlyStickerAmount(params) {
  return request({
    url: '/operating/getRecentlyStickerAmount',
    method: 'get',
    params
  })
}

export function getSkuRanking(params) {
  return request({
    url: '/operating/getSkuRanking',
    method: 'get',
    params
  })
}

export function getMiddleWorkData(params) {
  return request({
    url: '/operating/getMiddleWorkData',
    method: 'get',
    params
  })
}
