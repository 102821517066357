<template>
  <loading-box
      class="card"
      :style="{backgroundColor: backColor}"
      :loading="loading"
  >
    <div class="title">{{ title }}</div>
    <div class="value">{{ data ? data.value : '-' }}</div>
    <div class="bottom-warp">
      <div class="avg">日均：{{ data ? data.avg : '-' }}</div>
      <div class="radio" :class="{up: (data ? data.ratio : 0) > 0, down: (data ? data.ratio : 0) < 0 }" >环比：<span >{{ data ? data.ratio : '-' }}%</span></div>
    </div>
  </loading-box>
</template>

<script>
import LoadingBox from "@/components/LoadingBox.vue";

export default {
  components: {
    LoadingBox
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    backColor: {
      type: String,
      default: '#5D9CED'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped lang="less">
.card{
  height: 4rem;
  padding: .6rem;
  text-align: left;
  background-color: #5D9CED;
  color: #ffffff;
  border-radius: .3rem;
  .title{
    font-size: .8rem;
  }
  .value{
    font-weight: bold;
  }
  .bottom-warp{
    display: flex;
    justify-content: space-between;
    font-size: .6rem;
    .radio{
      span{
        color: #FFD700;
      }
      &.up {
        span{
          color: greenyellow;
        }
      }
      &.down{
        span{
          color: red;
        }
      }
    }
  }
}
</style>
