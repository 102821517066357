import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index'
import bind from "@/views/bind/index.vue";
import operating from "@/views/operating/index.vue";
import stockWarning from "@/views/stockWarning/index.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      title: 'MinoShipping',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login'),
    meta: {
      title: 'MinoShipping',
    }
  },
  {
    path: '/bind',
    name: 'bind',
    component: bind,
    meta: {
      title: '绑定店铺',
    },
  },
  {
    path: '/operating/:userId',
    name: 'operating',
    component: operating,
    meta: {
      title: '经营报表',
    },
  },
  {
    path: '/stockWarning/:userId',
    name: 'stockWarning',
    component: stockWarning,
    meta: {
      title: '库存预警',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
