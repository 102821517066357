<template>
  <div class="wrap">
    <template v-if="businessLoading">
      <loading-box class="business-loading" :loading="businessLoading" loading-text="加载中..."></loading-box>
    </template>

    <template v-else>
      <div class="header">
        <div class="title">{{ userInfo? userInfo.account : '' }}经营报表</div>
        <div class="select">
          <div class="date" @click="calendarVisible = true">
            <span>{{ dateText }}</span>
            <svg-icon class="icon" icon-class="arrow-down" />
          </div>
<!--          <div class="account" @click="accountActionSheetVisible = true">
            <span>{{ accountName }}</span>
            <svg-icon class="icon" icon-class="arrow-down" />
          </div>-->
        </div>
      </div>
      <van-notice-bar mode="link" left-icon="info-o" v-if="showStockWarningCount" @click="toStockWarningPage()">
        仓库库存中有 {{ stockWarningCount }} 个SKU库存数量低于预警值
      </van-notice-bar>
      <div class="body">
        <div class="cards">
          <card
            title="新增订单"
            back-color="#5D9CED"
            :loading="statisticsDataLoadings.orderCountData"
            :data="statisticsData.orderCountData"
          ></card>
          <card
              title="销售额（元）"
              back-color="#36AFDB"
              :loading="statisticsDataLoadings.orderAmountData"
              :data="statisticsData.orderAmountData"
          ></card>
          <card
              title="新增代贴单"
              back-color="#85C0D9"
              :loading="statisticsDataLoadings.stickerCountData"
              :data="statisticsData.stickerCountData"
          ></card>
          <card
              title="贴单费用（元）"
              back-color="#7DB1B2"
              :loading="statisticsDataLoadings.stickerAmountData"
              :data="statisticsData.stickerAmountData"
          ></card>
        </div>
        <loading-box
            class="middle-work line-item"
            :loading="statisticsDataLoadings.middleWorkData"
        >
          <div class="title">大货中转</div>
          <div class="data">
            <div class="line count">
              <div class="item">
                <span class="value">{{ statisticsData.middleWorkData ? statisticsData.middleWorkData.middleWorkCount : 0 }}</span>
                <span class="tips">送仓工单</span>
              </div>
              <div class="item">
                <span class="value">{{ statisticsData.middleWorkData ? statisticsData.middleWorkData.transferWorkCount : 0 }}</span>
                <span class="tips">调货工单</span>
              </div>
              <div class="item">
                <span class="value">{{ statisticsData.middleWorkData ? statisticsData.middleWorkData.labelOrderCount : 0 }}</span>
                <span class="tips">贴标工单</span>
              </div>
            </div>
            <div class="line amount">
              <div class="item">
                <span class="value">{{ statisticsData.middleWorkData ? statisticsData.middleWorkData.middleWorkAmount : 0 }}</span>
                <span class="tips">送仓费用（元）</span>
              </div>
              <div class="item">
                <span class="value">{{ statisticsData.middleWorkData ? statisticsData.middleWorkData.transferWorkAmount : 0 }}</span>
                <span class="tips">调货费用（元）</span>
              </div>
              <div class="item">
                <span class="value">{{ statisticsData.middleWorkData ? statisticsData.middleWorkData.labelOrderAmount : 0 }}</span>
                <span class="tips">贴标费用（元）</span>
              </div>
            </div>
          </div>
        </loading-box>
        <loading-box
            class="line-item"
            :loading="statisticsDataLoadings.recentlyOrderAmountData"
        >
          <div class="title">近七日销售趋势</div>
          <div class="chart" id="order-amount"></div>
        </loading-box>
        <loading-box
            class="line-item"
            :loading="statisticsDataLoadings.recentlyCountData"
        >
          <div class="title">近七日贴单趋势</div>
          <div class="chart" id="order-count"></div>
        </loading-box>
        <loading-box
            class="line-item"
            :loading="statisticsDataLoadings.recentlyStickerAmountData"
        >
          <div class="title">近七日贴单费用趋势</div>
          <div class="chart" id="sticker-amount"></div>
        </loading-box>
        <loading-box
            class="line-item"
            :loading="statisticsDataLoadings.skuRanking"
        >
          <div class="title">销售额SKU榜单</div>
          <div class="sku-ranking">
            <div
                class="item"
                v-for="(item, index) in statisticsData.skuRanking"
                :key="index"
            >
              <div class="ranking">
                <ranking-nubmer :ranking="index + 1"></ranking-nubmer>
              </div>
              <div class="sku">
                {{ item.sku }}
              </div>
              <div class="amount">
                ￥{{ item.amount }}
              </div>
            </div>
          </div>
        </loading-box>
      </div>
    </template>

    <van-calendar
        v-model="calendarVisible"
        type="range"
        @confirm="selectDates"
        color="#3CA9FF"
        :allow-same-day="true"
        :max-date="calendarOptions.maxDate"
        :min-date="calendarOptions.minDate"
        :default-date="calendarDefaultDate"
        :first-day-of-week="1"
    />

    <van-action-sheet
        v-model="accountActionSheetVisible"
        :actions="accountActions"
        @select="selectAccount"
        description="请选择店铺"
    />
  </div>
</template>

<script>

import LoadingBox from "@/components/LoadingBox.vue";
import SvgIcon from "@/components/SvgIcon/index.vue";
import {dateFormat} from "@/utils";
import {getSiteName} from "@/utils/sites";
import {
  getUserInfo, getRecentlyCount, getRecentlyOrderAmount, getRecentlyStickerAmount, getSkuRanking, orderAmountData,
  orderCountData,
  stickerAmountData,
  stickerCountData, getMiddleWorkData
} from "@/api/operating";
import RankingNubmer from "@/views/operating/components/RankingNubmer.vue";
import Card from "@/views/operating/components/Card.vue";
import * as echarts from "echarts";

const defaultMaxDate = new Date();
const defaultMinDate = new Date((new Date()).getTime() - 86400 * 1000 * 365);

export default {
  components: {
    Card,
    RankingNubmer,
    SvgIcon,
    LoadingBox,
  },
  data() {
    return {
      params: {
        userId: null,
        startDay: '',
        endDay: '',
        accountId: null,
      },
      businessLoading: false,
      userInfo: null,
      accounts: [],
      statisticsData: {
        orderCountData: null,
        orderAmountData: null,
        stickerCountData: null,
        stickerAmountData: null,
        recentlyOrderAmountData: [],
        recentlyCountData: [],
        recentlyStickerAmountData: [],
        skuRanking: [],
        middleWorkData: null,
      },
      statisticsDataLoadings: {
        orderCountData: false,
        orderAmountData: false,
        stickerCountData: false,
        stickerAmountData: false,
        recentlyOrderAmountData: false,
        recentlyCountData: false,
        recentlyStickerAmountData: false,
        skuRanking: false,
        middleWorkData: false,
      },
      calendarVisible: false,
      calendarOptions: {
        maxDate: defaultMaxDate,
        minDate: defaultMinDate,
      },
      accountActionSheetVisible: false,
      stockWarningCount: 0,
      showStockWarningCount: false,

    }
  },
  created() {
    // 绑定 userId
    this.params.userId = this.$route.params.userId;
    this.getBusiness();

    // 全部重置一次时间
    const today = new Date((new Date()).getTime() + this.diffHours * 3600 * 1000);
    const maxDate = new Date(defaultMaxDate.getTime() + this.diffHours * 3600 * 1000);
    const minDate = new Date(defaultMinDate.getTime() + this.diffHours * 3600 * 1000);
    this.calendarOptions.maxDate = maxDate;
    this.calendarOptions.minDate = minDate;

    // 处理日期
    if (this.$route.query.daysRange) {
      // 从  url 里面获取
      if (this.$route.query.daysRange.indexOf(' - ') > 0){
        const [startDay, endDay] = this.$route.query.daysRange.split(' - ');
        this.params.startDay = startDay;
        this.params.endDay = endDay;
      } else {
        this.params.startDay = this.$route.query.daysRange;
        this.params.endDay = this.$route.query.daysRange;
      }
    } else {
      // 默认今天
      this.params.startDay = dateFormat('Y-m-d', today);
      this.params.endDay = dateFormat('Y-m-d', today);
    }
  },
  computed: {
    dateText() {
      if (this.params.startDay === this.params.endDay) {
        return this.params.startDay;
      } else {
        return `${this.params.startDay} 至 ${this.params.endDay}`;
      }
    },
    accountName() {
      if (this.params.accountId) {
        const item = this.accounts.find(v => v.accountId === this.params.accountId);
        return item ? item.nickname : '???';
      } else {
        return '全部店铺';
      }
    },
    calendarDefaultDate() {
      return [
        new Date(this.params.startDay.replace(/-/g, '/')),
        new Date(this.params.endDay.replace(/-/g, '/')),
      ];
    },
    accountActions() {
      const actions = [
        {
          name: '全部店铺',
          accountId: null,
        }
      ];
      this.accounts.forEach(item => {
        actions.push({
          name: item.nickname,
          accountId: item.accountId,
        });
      });
      return actions;
    },
    diffHours() {
      return this.$store.state.userInfo ? this.$store.state.userInfo.diffHours : 0;
    },
  },
  methods: {
    getBusiness() {
      this.businessLoading = true;
      getUserInfo(this.params).then(res => {
        if (res.error === 0) {
          this.businessLoading = false;
          this.userInfo = res.data.userInfo;
          this.accounts = res.data.accountData;
          this.stockWarningCount = res.data.stockWarningCount;
          this.showStockWarningCount = res.data.showStockWarningCount;
          this.getAllStatistics();
        } else {
          this.$showMessageTips('无店铺权限\n请授权绑定后再访问');
        }
      }).catch(() => {
        this.$showMessageTips('获取经营报表失败');
      });
    },
    requestStatisticsData(method) {
      return new Promise((resolve, reject) => {
        method(this.params)
            .then(res => {
              if (res.error === 0) {
                resolve(res.data);
              } else {
                reject(res.message);
              }
            })
            .catch(reject)
      })

    },
    orderCountData() {
      this.statisticsDataLoadings.orderCountData = true;
      this.requestStatisticsData(orderCountData)
          .then(data => {
            this.statisticsDataLoadings.orderCountData = false;
            this.statisticsData.orderCountData = data;
          });
    },
    orderAmountData() {
      this.statisticsDataLoadings.orderAmountData = true;
      this.requestStatisticsData(orderAmountData)
          .then(data => {
            this.statisticsDataLoadings.orderAmountData = false;
            this.statisticsData.orderAmountData = data;
          });
    },
    stickerCountData() {
      this.statisticsDataLoadings.stickerCountData = true;
      this.requestStatisticsData(stickerCountData)
          .then(data => {
            this.statisticsDataLoadings.stickerCountData = false;
            this.statisticsData.stickerCountData = data;
          });
    },
    stickerAmountData() {
      this.statisticsDataLoadings.stickerAmountData = true;
      this.requestStatisticsData(stickerAmountData)
          .then(data => {
            this.statisticsDataLoadings.stickerAmountData = false;
            this.statisticsData.stickerAmountData = data;
          });
    },
    getRecentlyOrderAmount() {
      this.statisticsDataLoadings.recentlyOrderAmountData = true;
      this.requestStatisticsData(getRecentlyOrderAmount)
          .then(data => {
            const charts = echarts.init(document.getElementById('order-amount'));
            charts.resize();
            const days = [];
            const amounts = [];
            data.forEach(v => {
              const day = v.day.slice(-2) + '日';
              days.push(day);
              amounts.push(v.amount);
            });
            const {max, interval} = this.generateYAxisLabels(amounts);
            const options = {
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '3%',
                right: '3%',
                bottom: '8%', // 调整底部间距
                top: '10%',
                containLabel: true // 确保标签被容纳在图表区域内
              },
              xAxis: {
                type: 'category',
                data: days,
                axisLabel: {
                  interval: 0 // 强制显示所有标签
                }
              },
              yAxis: {
                type: 'value',
                min: 0,
                max: max,
                interval: interval,
              },
              series: [
                {
                  data: amounts,
                  type: 'bar',
                  label: {
                    show: true, // 显示标签
                    position: 'top', // 标签的位置
                    // 可选的，标签的格式化
                    formatter: '{c}' // {c} 表示当前数值
                  },
                }
              ]
            };
            charts.setOption(options);
            this.statisticsDataLoadings.recentlyOrderAmountData = false;
            this.statisticsData.recentlyOrderAmountData = data;
          });
    },
    getRecentlyCount() {
      this.statisticsDataLoadings.recentlyAmountData = true;
      this.requestStatisticsData(getRecentlyCount)
          .then(data => {
            const charts = echarts.init(document.getElementById('order-count'));
            charts.resize();
            const days = [];
            const orderCounts = [];
            const stickerCounts = [];
            data.forEach(v => {
              const day = v.day.slice(-2) + '日';
              days.push(day);
              orderCounts.push(v.orderCount);
              stickerCounts.push(v.stickerCount);
            });
            const {max, interval} = this.generateYAxisLabels(orderCounts.concat(stickerCounts));
            const options = {
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '3%',
                right: '3%',
                bottom: '18%', // 调整底部间距
                top: '10%',
                containLabel: true // 确保标签被容纳在图表区域内
              },
              legend: {
                data: ['新增订单', '代贴单'],
                bottom: '2%', // 也可以使用像素值，例如 '10px'
                left: 'center', // 水平居中
              },
              xAxis: {
                type: 'category',
                data: days,
                axisLabel: {
                  interval: 0 // 强制显示所有标签
                }
              },
              yAxis: {
                type: 'value',
                min: 0,
                max: max,
                interval: interval,
              },
              series: [
                {
                  name: '新增订单',
                  data: orderCounts,
                  type: 'bar',
                  label: {
                    show: true, // 显示标签
                    position: 'top', // 标签的位置
                    // 可选的，标签的格式化
                    formatter: '{c}' // {c} 表示当前数值
                  },
                },
                {
                  name: '代贴单',
                  data: stickerCounts,
                  type: 'bar',
                  label: {
                    show: true, // 显示标签
                    position: 'top', // 标签的位置
                    // 可选的，标签的格式化
                    formatter: '{c}' // {c} 表示当前数值
                  },
                },
              ]
            };
            charts.setOption(options);
            this.statisticsDataLoadings.recentlyCountData = false;
            this.statisticsData.recentlyCountData = data;
          });
    },
    getRecentlyStickerAmount() {
      this.statisticsDataLoadings.recentlyOrderAmountData = true;
      this.requestStatisticsData(getRecentlyStickerAmount)
          .then(data => {
            const charts = echarts.init(document.getElementById('sticker-amount'));
            charts.resize();
            const days = [];
            const amounts = [];
            data.forEach(v => {
              const day = v.day.slice(-2) + '日';
              days.push(day);
              amounts.push(v.amount);
            });
            const {max, interval} = this.generateYAxisLabels(amounts);
            const options = {
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '3%',
                right: '3%',
                bottom: '8%', // 调整底部间距
                top: '10%',
                containLabel: true // 确保标签被容纳在图表区域内
              },
              xAxis: {
                type: 'category',
                data: days,
                axisLabel: {
                  interval: 0 // 强制显示所有标签
                }
              },
              yAxis: {
                type: 'value',
                min: 0,
                max: max,
                interval: interval,
              },
              series: [
                {
                  data: amounts,
                  type: 'bar',
                  label: {
                    show: true, // 显示标签
                    position: 'top', // 标签的位置
                    // 可选的，标签的格式化
                    formatter: '{c}' // {c} 表示当前数值
                  },
                }
              ]
            };
            charts.setOption(options);
            this.statisticsDataLoadings.recentlyStickerAmountData = false;
            this.statisticsData.recentlyStickerAmountData = data;
          });
    },
    getSkuRanking() {
      this.statisticsDataLoadings.skuRanking = true;
      this.requestStatisticsData(getSkuRanking)
          .then(data => {
            this.statisticsDataLoadings.skuRanking = false;
            this.statisticsData.skuRanking = data;
          });
    },
    getAllStatistics() {
      this.orderCountData();
      this.orderAmountData();
      this.stickerCountData();
      this.stickerAmountData();
      this.getRecentlyOrderAmount();
      this.getRecentlyCount();
      this.getRecentlyStickerAmount();
      this.getSkuRanking();
      this.getMiddleWorkData();

    },
    selectDates(date) {
      const [start, end] = date;
      this.params.startDay = dateFormat('Y-m-d', start);
      this.params.endDay = dateFormat('Y-m-d', end);
      this.calendarVisible = false;
      this.getAllStatistics();
    },
    selectAccount({name, accountId}) {
      this.params.accountId = accountId;
      this.accountActionSheetVisible = false;
      this.getAllStatistics();
    },
    generateYAxisLabels(values) {
      const maxValue = Math.max.apply(null, values)
      console.log(maxValue);

      let interval;
      let max;

      if (maxValue <= 50) {
        interval = 10;
      } else if (maxValue <= 100) {
        interval = 20;
      } else if (maxValue <= 500) {
        interval = 100;
      } else if (maxValue <= 1000) {
        interval = 200;
      } else {
        interval = Math.ceil(maxValue / 1000) * 200;
      }

      max = Math.ceil(maxValue / interval) * interval;

      return { max, interval };
    },
    toStockWarningPage() {
      this.$router.push({
        name: 'stockWarning',
        params: {
          userId: this.params.userId,
        }
      })
    },
    getMiddleWorkData() {
      this.statisticsDataLoadings.middleWorkData = true;
      this.requestStatisticsData(getMiddleWorkData)
          .then(data => {
            this.statisticsDataLoadings.middleWorkData = false;
            this.statisticsData.middleWorkData = data;
          });
    },
  }
}
</script>

<style scoped lang="less">
@import "~@/styles/header.less";
.wrap{
  background-color: #F2F2F2;
  min-height: 100vh;
}
.header {
  .title {
    text-align: left;
    font-weight: bold;
    padding: 0 0 .3rem;
  }
  .select{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 0 0 1rem;
    .icon{
      font-size: .5rem;
      padding-left: .6rem;
    }
  }
}
.body{
  padding: .8rem 1rem;
}
.business-loading{
  height: 100vh;
}
.cards{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: .8rem;
  grid-row-gap: .8rem;
}
.line-item{
  background-color: #ffffff;
  margin-top: 1rem;
  text-align: left;
  padding: .6rem .5rem;
  border-radius: .3rem;
  .title{
    font-size: .8rem;
    color: #333333;
    padding: 0 0 .6rem;
  }
}
.chart{
  padding: 0 0 0;
  height: 15rem;
  width: 100%;
}
.sku-ranking{
  text-align: left;
  min-height: 3rem;
  .item{
    font-size: .8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 .3rem;
    & > div{
      flex: 0 0 auto;
    }
    .ranking{
      width: 15%;
    }
    .sku{
      // 强制换行，单词不连贯也换行
      word-break: break-all;
      width: 60%;
    }
    .amount{
      width: 20%;
      padding-left: 1rem;
    }
  }
}
.middle-work{
  .data{
    .line{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 .3rem;
      margin-bottom: .6rem;
      &.amount{
        .value{
          color: #F69A25 !important;
        }
      }
      .item{
        width: 33%;
        font-size: .8rem;
        text-align: center;
        & > span{
          display: block;
        }
        border-right: 1px solid #CCCCCC;
        &:last-child{
          border-right: none;
        }
        .value{
          font-size: 1.2rem;
          font-weight: bold;
          padding-right: .5rem;
          color: #4889DC;
        }
        .tips{
          font-size: .7rem;
          color: #AAAAAA;
        }
      }
    }
  }
}
</style>
