import Vue from 'vue'
import Vuex from 'vuex'
import {getUserInfo} from "@/api/auth";
import {GET_USER_INFO, SET_SHOW_CHAT_BOX, SET_SHOW_CHAT_BUTTON, SET_USER_INFO} from "@/store/mutation-types";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 用户信息
    userInfo: null,
    // 是否显示聊天按钮
    showChatButton: false,
    // 是否显示聊天窗口
    showChatBox: false,
  },
  mutations: {
    [SET_USER_INFO]: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    [SET_SHOW_CHAT_BUTTON]: (state, showChatButton) => {
      state.showChatButton = showChatButton;
    },
    [SET_SHOW_CHAT_BOX]: (state, showChatBox) => {
      state.showChatBox = showChatBox;
    },
  },
  actions: {
    [GET_USER_INFO](store){
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            if (res.error === 0) {
              store.commit(SET_USER_INFO, res.data);
              resolve();
            } else {
              reject(new Error(res.message));
            }
          })
          .catch(error => {
            reject(error);
          })
      })
    }
  },
  modules: {
  }
})
