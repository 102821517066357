import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Button, Loading, Notify, Toast, Icon, Field, List, Uploader, Dialog, Cell, CellGroup, Picker, Popup, Tabs, Tab,
  Calendar, ActionSheet, NoticeBar, Overlay
} from 'vant';
import request from "@/utils/request";

// 全局样式
import '@/styles/base.less';

import './icons' // icon
// 登录控制
import '@/permission';

// 注册vant组件
Vue.use(Button)
  .use(Loading)
  .use(Notify)
  .use(Toast)
  .use(Icon)
  .use(Field)
  .use(List)
  .use(Uploader)
  .use(Dialog)
  .use(Cell)
  .use(CellGroup)
  .use(Picker)
  .use(Popup)
  .use(Tabs)
  .use(Tab)
  .use(Calendar)
  .use(ActionSheet)
  .use(NoticeBar)
  .use(Overlay)

Vue.config.productionTip = false

// 请求封装
Vue.prototype.$request = opt => {
  return request(opt);
}

// 提示信息封装
Vue.prototype.$message = (text, duration = 2000) => {
  Toast({
    message: text,
    duration
  });
}
Vue.prototype.$successMessage = text => {
  Toast.success(text);
}
Vue.prototype.$errorMessage = text => {
  Toast.fail(text);
}
Vue.prototype.$showMessageTips = (message, title = '') => {
  Dialog.confirm({
    title: title,
    message: message,
    showConfirmButton: false,
    showCancelButton: false,
    // confirmButtonText: '退出',
    beforeClose: (action, done) => {
      if (action === 'confirm') {
        // eslint-disable-next-line no-undef
      }
    }
  })
    .then(() => {
      window.WeixinJSBridge.call('closeWindow');
      window.close();
      // on confirm
    })
    .catch(() => {
      window.WeixinJSBridge.call('closeWindow');
      window.close();
      // on cancel
    });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
