<template>
  <div class="loading-box">
    <div class="loading-container" v-show="loading">
      <van-loading vertical>{{ loadingText ? loadingText : '' }}</van-loading>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped lang="less">
.loading-box{
  position: relative;
  .loading-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // 加一层遮罩
    background-color: rgba(255, 255, 255, .8);
  }
  .content{
    width: 100%;
  }
}
</style>
