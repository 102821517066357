<template>
  <div class="wrap">
    <div class="header">
      {{ appName }} 库存预警
    </div>
    <div class="content">
      <van-list
          class="list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="title">
          当前账号在{{ appName }}仓库中有 <span>{{ allSkuCount }}</span> 个SKU库存数量低于预警值  {{ stockTipsNum }}，请及时补充库存
        </div>
        <table
            class="data"
        >
          <thead>
          <tr>
            <th>SKU</th>
            <th>一件代发库存</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td>
              <div class="skus">
                <div v-for="(sku,ii) in item.skus" :key="ii">{{ sku }}</div>
              </div>
            </td>
            <td>
              <span class="red">{{ item.stockNumCount }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </van-list>
    </div>
  </div>
</template>

<script>
import {getStockWarningList} from "@/api/stockWarning";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      params: {
        userId: null,
        page: 1,
        pageSize: 10,
      },
      data: [],
      total: 0,
      stockTipsNum: '',
      allSkuCount: '',
      appName: '',
    }
  },
  created() {
    // 绑定 userId
    this.params.userId = this.$route.params.userId;
  },
  methods: {
    onLoad() {
      console.log('onload');
      this.getList();
    },
    getList() {
      getStockWarningList(this.params)
          .then(res => {
            this.params.page++;
            if (res.error === 0) {
              this.data = [...this.data, ...res.data];
              this.total = res.info.total;
              this.stockTipsNum = res.info.stockTipsNum;
              this.allSkuCount = res.info.allSkuCount;
              this.appName = res.info.appName;

              if (res.data.length === 0) {
                this.finished = true;
              }
            } else {
              this.$showMessageTips('无店铺权限\n请授权绑定后再访问');
              this.finished = true;
            }
            this.loading = false;
          })
          .catch(error => {
            this.$errorMessage("系统错误，请稍后再试");
          })
    },
  }
}
</script>

<style scoped lang="less">
@import "~@/styles/header.less";
.wrap{
  height: 100vh;
}
.header{
  text-align: left;
  padding: .8rem 0 .8rem 1rem;
  font-weight: bold;
}
.content{
  color: #333333;
  background-color: #F2F2F2;
  min-height: 100%;
  font-size: .9rem;
  padding: .6rem;
  .title{
    text-align: left;
    margin-bottom: .8rem;
  }
}
.list{
  background-color: #FFFFFF;
  padding: .8rem;
}
table.data{
  width: 100%;
  font-size: .8rem;
  border-collapse: collapse;
  margin: auto;
  border: 1px solid #D7D7D7;
  th, td{
    border: 1px solid #D7D7D7;
    padding: 10px;
    text-align: center;
  }
  .red{
    color: red;
    font-weight: bold;
  }
}
</style>
