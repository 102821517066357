import request from "@/utils/request";

/**
 * 获取库存预警列表
 * @param params
 */
export function getStockWarningList(params) {
  return request({
    url: '/stockWarning/getStockWarningList',
    method: 'get',
    params,
  })
}
