<template>
  <div
      class="ranking-number"
      :class="[`ranking-${ranking}`]"
  >
    {{ ranking }}
  </div>
</template>

<script>
export default {
  props: {
    ranking: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped lang="less">
.ranking-number{
  @length: 1.6rem;
  width: @length;
  height: @length;
  border-radius: 50%;
  background-color: #F0F2F5;
  text-align: center;
  line-height: @length;
  &.ranking-1{
    color: #ffffff;
    background-color: #F7232E;
  }
  &.ranking-2{
    color: #ffffff;
    background-color: #F69A25;
  }
  &.ranking-3{
    color: #ffffff;
   background-color: #FBCD92;
  }
}
</style>
