<template>
  <div>

  </div>
</template>

<script>

import {bind} from "@/api/bind";

export default {
  data() {
    return {
      loading: false,

    }
  },
  created() {
    if (this.$route.query.bindKey) {
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '同步中...',
      });

      bind(this.$route.query.bindKey)
        .then(res => {
          toast.clear();
          if (res.error === 0) {
            this.$showMessageTips('系统将根据您设置的发送频率，为您推送通知\n您可直接关闭当前页面', '绑定成功');
          } else {
            console.log(res.message)
            this.$showMessageTips('请稍后再尝试或联系客服处理', '绑定失败');
          }
        })
          .catch(() => {
            toast.clear();
            this.$showMessageTips('系统繁忙，请稍后再试');
          });
    } else {
      this.$showMessageTips('链接失效\n请重新扫码');
    }
  },
  methods: {


  }
}
</script>

<style scoped lang="less">

</style>
